import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
import queryString from 'qs';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = theme => ({
  flex:{
    flex: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  paperAppBar:{
    marginTop:"-1%",
    marginBottom:"1%"
  },
  toolbar: theme.mixins.toolbar,
  button:{
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3
  },
  paper: theme.mixins.gutters({
    paddingBottom: 30,
    width: "100%",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: theme.spacing.unit * 3,
  }),
  textFieldHalf:{
    margin: 5,
    width: "50%"
  }
});

class Integrations extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      public_key: "",
      tokenization_key: "",
      sandbox_public_key: "",
      sandbox_tokenization_key: ""
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   this.setState({ snackOpen: false });
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

componentDidMount(event){
  const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
  console.log(query)
  if (query.code) {
    this.updateOrg(query.code)
  }else{
    this.fetchUser()
  }
}

fetchUser = () => {
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    if(response.status === 200){
      self.setState({
      org: response.data,
      finished: true,
      tokenization_key: response.data.braintree_credentials && response.data.braintree_credentials.tokenization_key || "",
      public_key: response.data.braintree_credentials && response.data.braintree_credentials.public_key || "",
      sandbox_tokenization_key: response.data.braintree_credentials && response.data.braintree_credentials.sandbox_tokenization_key || "",
      sandbox_public_key: response.data.braintree_credentials && response.data.braintree_credentials.sandbox_public_key || ""
      });
    }else{
      let path = `/${self.state.org_handle}/login`
      self.props.history.push(path);
    }
  }).catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving user profile"})
  });
}

updateOrg = (event) => {
  event.preventDefault()
  var self = this;
  self.setState({
  braintreeUpdateFinished: false
  });
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/settings/integrations/braintree`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     "type": "production",
     "private_key": this.state.private_key,
     "public_key": this.state.public_key,
     "merchant_id": this.state.merchant_id,
     "tokenization_key": this.state.tokenization_key,

   }
 })
  .then(function (response) {
    if(response.status === 200){

      self.setState({
        braintreeUpdating: false,
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Credentials have been updated."
      });
      self.fetchUser()
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      braintreeUpdating: false,
      snackOpen: true,
      snackVariant:"error",
      snackMessage:"Error updating credentials. Double check they were copied correctly."
    });
    self.fetchUser()
    self.setState({messageError:"Error updating user"})
  });
}

updateOrgSandbox = (event) => {
  event.preventDefault()
  var self = this;
  self.setState({
  braintreeUpdateFinished: false
  });
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/settings/integrations/braintree`,
   headers: {
     'Authorization': 'Bearer ' + localStorage.getItem('token'),
     'Content-Type' : 'application/json'
   },
   data:{
     "type": "sandbox",
     "sandbox_private_key": this.state.sandbox_private_key,
     "sandbox_public_key": this.state.sandbox_public_key,
     "sandbox_merchant_id": this.state.sandbox_merchant_id,
     "sandbox_tokenization_key": this.state.sandbox_tokenization_key
   }
 })
  .then(function (response) {
    if(response.status === 200){

      self.setState({
        braintreeUpdating: false,
        snackOpen: true,
        snackVariant:"success",
        snackMessage:"Credentials have been updated."
      });
      self.fetchUser()
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({
      braintreeUpdating: false,
      snackOpen: true,
      snackVariant:"error",
      snackMessage:"Error updating credentials. Double check they were copied correctly."
    });
    self.fetchUser()
    self.setState({messageError:"Error updating user"})
  });
}

render() {
  const { classes, ...other } = this.props;


    return (

      <NavBar2 header="Integrations" {...other}>

      <Grid container spacing={24}>

      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="primary">
         <Toolbar variant="regular">
           <Typography variant="title" color="inherit" className={classes.flex}>
             Production Braintree Connection - <a style={{color: "#fff"}} rel="noopener noreferrer" target="_blank" href="https://articles.braintreepayments.com/control-panel/important-gateway-credentials">Where can I find these?</a>
           </Typography>
           <Typography
           variant="headliner"
           color="inherit"
           style={this.state.org && this.state.org.braintree_credentials ? {color: "#74d874"} : {color: "red"} }
          >
             {this.state.org && this.state.org.braintree_credentials ? "Connected" : "Disconnected" }
           </Typography>
         </Toolbar>
       </AppBar>
       <form onSubmit={this.updateOrg} autocomplete="off">

       <TextField
       label="Secret Key"
       name="braintree_secret"
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Secret. If already entered, it is hidden for security purposes."
       onChange={this.handleChange('private_key')}
       />
       <TextField
       label="Public Key"
       name="public_key"
       value={this.state.public_key}
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Public Key"
       onChange={this.handleChange('public_key')}
       />
       <TextField
       label="Merchant ID"
       name="merchant_id"
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Merchant ID. If already entered, it is hidden for security purposes."
       onChange={this.handleChange('merchant_id')}
       />
       <TextField
       label="Tokenization Key"
       name="tokenization_key"
       value={this.state.tokenization_key}
       required
       className={classes.textFieldHalf}
       helperText="Enter a Braintree Tokenization Key"
       onChange={this.handleChange('tokenization_key')}
       />
       <Grid container justify={"center"}>
       <Button
       variant="raised"
       color="primary"
       disabled={this.state.createOrgDisabled}
       type="submit"
       className={classes.button}

       >
       Update Production Braintree Connection
       </Button>
       </Grid>
       </form>

      </Paper>


      <Paper className={classes.paper} elevation={4}>
      <AppBar position="static" className={classes.paperAppBar} color="primary">
         <Toolbar variant="regular">
           <Typography variant="title" color="inherit" className={classes.flex}>
             Sandbox Braintree Connection - <a style={{color: "#fff"}} rel="noopener noreferrer" target="_blank" href="https://articles.braintreepayments.com/control-panel/important-gateway-credentials">Where can I find these?</a>
           </Typography>
           <Typography
           variant="headliner"
           color="inherit"
           style={this.state.org && this.state.org.braintree_credentials ? {color: "#74d874"} : {color: "red"} }
          >
             {this.state.org && this.state.org.braintree_credentials ? "Connected" : "Disconnected" }
           </Typography>
         </Toolbar>
       </AppBar>
       <form onSubmit={this.updateOrgSandbox} autocomplete="off">

       <TextField
       label="Sandbox Secret Key"
       name="braintree_secret"
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Secret. If already entered, it is hidden for security purposes."
       onChange={this.handleChange('sandbox_private_key')}
       />
       <TextField
       label="Sandbox Public Key"
       name="public_key"
       value={this.state.sandbox_public_key}
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Public Key"
       onChange={this.handleChange('sandbox_public_key')}
       />
       <TextField
       label="Sandbox Merchant ID"
       name="merchant_id"
       required
       className={classes.textFieldHalf}
       helperText="Enter your Braintree Merchant ID. If already entered, it is hidden for security purposes."
       onChange={this.handleChange('sandbox_merchant_id')}
       />
       <TextField
       label="Sandbox Tokenization Key"
       name="tokenization_key"
       value={this.state.sandbox_tokenization_key}
       required
       className={classes.textFieldHalf}
       helperText="Enter a Braintree Tokenization Key"
       onChange={this.handleChange('sandbox_tokenization_key')}
       />
       <Grid container justify={"center"}>
       <Button
       variant="raised"
       color="primary"

       disabled={this.state.createOrgDisabled}
       type="submit"
       className={classes.button}

       >
       Update Sandbox Braintree Connection
       </Button>
       </Grid>
       </form>

      </Paper>


      </Grid>
      <Snackbar
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         open={this.state.snackOpen}
         autoHideDuration={3000}
         onClose={this.handleSnackClose}
         >
           <MySnackbarContentWrapper
           variant={this.state.snackVariant}
           message={this.state.snackMessage}
           onClose={this.handleSnackClose}
          />
      </Snackbar>
      </NavBar2>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Integrations);
