import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NavBar2 from '../../components/navigation/NavBar2';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const drawerWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paginationButton:{
    margin: theme.spacing.unit
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
  paper: theme.mixins.gutters({
    paddingTop: 50,
    paddingBottom: 50,
    //marginTop: theme.spacing.unit * 3,
  }),
  grow: {
     flexGrow: 1,
   },
   menuButton: {
     marginLeft: -12,
     marginRight: 20,
   },
   title: {
     display: 'none',
     [theme.breakpoints.up('sm')]: {
       display: 'block',
     },
   },
   search: {
     position: 'relative',
     borderRadius: theme.shape.borderRadius,
     backgroundColor: fade(theme.palette.common.white, 0.15),
     '&:hover': {
       backgroundColor: fade(theme.palette.common.white, 0.25),
     },
     marginLeft: 0,
     width: '100%',
     [theme.breakpoints.up('sm')]: {
       marginLeft: theme.spacing.unit,
       width: 'auto',
     },
   },
   searchIcon: {
     width: theme.spacing.unit * 9,
     height: '100%',
     position: 'absolute',
     pointerEvents: 'none',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
   },
   inputRoot: {
     color: 'inherit',
     width: '100%',
   },
   inputInput: {
     paddingTop: theme.spacing.unit,
     paddingRight: theme.spacing.unit,
     paddingBottom: theme.spacing.unit,
     paddingLeft: theme.spacing.unit * 10,
     transition: theme.transitions.create('width'),
     width: '100%',
     [theme.breakpoints.up('sm')]: {
       width: 120,
       '&:focus': {
         width: 200,
       },
     },
   },
});



class Customer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 10,
      customers:[],
      searchValue: ""
    };
  }

   changeNav = name => event => {
    let path = `/${this.props.match.params.org}/${name}`
    this.props.history.push(path);
 };

 handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
  };

  nextPage = (event) => {
    this.loadCustomers(Number(this.state.page)+1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page+1
    });
  }

  previousPage = (event) => {
    this.loadCustomers(Number(this.state.page)-1, Number(this.state.rowsPerPage))
    this.setState({
    page: this.state.page-1
    });
  }


componentDidMount(event){
  console.log(this.state)
  this.loadCustomers(Number(this.state.page), Number(this.state.rowsPerPage))

  window.intercomSettings = {
      app_id: "nhry3flh",
      user_hash: localStorage.getItem('intercom_hash')
      //created_at: <%= current_user.created_at.to_i %> // Signup Date
    };
}

loadCustomers = (page, per_page)=>{
  var self = this;
  axios({
   method: 'get',
   url: `${apiBaseUrl}/v1/org/customers?page=${page}&per_page=${per_page}`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      self.setState({
      customers: response.data,
      finished: true
      });
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving customers"})
  });
}

searchCustomers = ()=>{
  var self = this;
  if (!this.state.searchValue) {
    this.loadCustomers(Number(this.state.page), Number(this.state.rowsPerPage))
  }else{
  axios({
   method: 'POST',
   url: `${apiBaseUrl}/v1/org/customers/search`,
   headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
   data: {
     searchValue: this.state.searchValue
   }
 })
  .then(function (response) {
    console.log(response.data);
    if(response.status === 200){
      if (response.data.length > 0) {
      self.setState({
      customers: response.data,
      finished: true
      });
    }
    }})
  .catch(function (error) {
    console.log(error);
    self.setState({messageError:"Error retrieving customers"})
  });
}
}

render() {
  const { classes, ...other } = this.props;
  const customers = []
  console.log(this.state)
  for (var i = 0; i < this.state.customers.length; i++) {
    customers.push(
      <TableRow key={this.state.customers[i].id}>
        <TableCell component="th" scope="row">
          <Button size="small" variant="outlined" color="primary" target="_blank" href={`https://sandbox.braintreegateway.com/merchants/fbp2yfp227vq7dhc/customers/${this.state.customers[i].id}`} >
          View
          </Button>
        </TableCell>
        <TableCell >{this.state.customers[i].firstName + " " + this.state.customers[i].lastName}</TableCell>
        <TableCell >{this.state.customers[i].default_source ? "true" : "false"}</TableCell>
        <TableCell >{this.state.customers[i].email}</TableCell>
        <TableCell >{moment(new Date(this.state.customers[i].createdAt)).format('MM/DD/YYYY')}</TableCell>
        <TableCell >
        <Button size="small" variant="outlined" color="primary" target="_blank" href={`http://localhost:3002/forms/update/${this.state.customers[i].update_hash}`} >
        Update Page
        </Button>
        </TableCell>

      </TableRow>
    )}

    return (
      <div className={classes.root}>
        <NavBar2>

          {this.state.customers.length > 0 ?
          <Paper>
          <div>
    { /* <AppBar position="static">
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={
              (event)=> {
              event.preventDefault();
              this.searchCustomers();
              }
            }>
            <Input
              placeholder="Search..."
              disableUnderline
              onChange={this.handleChange('searchValue')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            </form>
          </div>
        </Toolbar>
      </AppBar>*/}
    </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>View in Braintree</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Card on File</TableCell>
                <TableCell >Email</TableCell>
                <TableCell >Created At</TableCell>
                <TableCell >Update Url</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            {customers}

            </TableBody>
            <TableFooter>
         <TableRow>
         <TableCell>
           Page {this.state.page}
         </TableCell>
         <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>
           <TableCell>
             <Button
             size="small"
             variant="contained"
             color="primary"
             onClick={(event) => this.previousPage(event)}
             className={classes.paginationButton}
             disabled={this.state.page <= 1}>
             Previous
             </Button>
             <Button
             size="small"
             variant="contained"
             color="primary"
             onClick={(event) => this.nextPage(event)}
             className={classes.paginationButton}
             disabled={this.state.customers.length < 10}>
             Next
             </Button>
           </TableCell>
         </TableRow>
       </TableFooter>
          </Table>
        </Paper>         :
                <div>
                <Grid container justify={"center"}>
                <Card className={classes.card}>
           <CardActionArea>
             <CardContent>
               <Typography gutterBottom variant="headline" component="h2">
                 You don't have a customer yet!
               </Typography>
               <Typography component="p">
                 Customers are pulled from Braintree. You can easily signup customers by creating a form in the forms tab.
               </Typography>
             </CardContent>
           </CardActionArea>
           <CardActions>
             <Button
             variant="contained"
             color="primary"
             href="/forms/new"
             >
               Create a Form
             </Button>
           </CardActions>
         </Card>
         </Grid>
         </div>
                }

        </NavBar2>
      </div>
    );
  }
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Customer);
