import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import UsersIcon from '@material-ui/icons/Face';
import UrlIcon from '@material-ui/icons/Http';
import AccountIcon from '@material-ui/icons/Business';
import IntegrationsIcon from '@material-ui/icons/DeviceHub';
import PagesIcon from '@material-ui/icons/InsertDriveFile';
import SettingsIcon from '@material-ui/icons/Settings';
import OneTimeIcon from '@material-ui/icons/SyncDisabled';
import RecurringIcon from '@material-ui/icons/Autorenew';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import PortalIcon from '@material-ui/icons/SettingsBrightness';
import {
  ExpandMore,
  ExpandLess,
  SettingsBrightness,
  ExitToApp,
  SyncDisabled,
  Settings,
  InsertDriveFile,
  SupervisorAccount,
  Person,
  Http,
  Store
} from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex:{
    flex:1
  },
  appFrame: {
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  'appBarShift-right': {
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
   ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  nested: {
    paddingLeft: theme.spacing.unit * 5,
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
});

class NavBar2 extends React.Component {
  state = {
    open: true,
    settingsOpen: false,
    anchor: 'left',
    app_livemode: false
  };


  handleSettingsClick = () => {
  this.setState(state => ({ settingsOpen: !this.state.settingsOpen }));
  };


  handleLiveMode = () => {
    this.setState({
      app_livemode: !this.state.app_livemode
    })

    localStorage.setItem('app_livemode', JSON.stringify({"app_livemode": this.state.app_livemode}));

  }



  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme, children } = this.props;
    const { open } = this.state;
    const anchor = "left";


    const drawer = (
      <Drawer
        variant="persistent"
        anchor={"left"}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
            <ListItem button component="a" href="/customers">
              <ListItemIcon>
                <SupervisorAccount />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
            <ListItem button component="a" href="/products">
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>
            <ListItem button component="a" href="/forms">
              <ListItemIcon>
                <PagesIcon />
              </ListItemIcon>
              <ListItemText primary="Forms" />
            </ListItem>
            <ListItem
            button
            onClick={this.handleSettingsClick}
            >
         <ListItemIcon>
           <Settings />
         </ListItemIcon>
         <ListItemText inset primary="Settings" />
         {this.state.settingsOpen ? <ExpandLess /> : <ExpandMore />}
       </ListItem>
       <Collapse in={this.state.settingsOpen} timeout="auto" unmountOnExit>
         <List component="div" disablePadding>

         <ListItem button
         className={classes.nested}
         component="a"
         href="/settings/profile"
         >
         <ListItemIcon>
         <Person />
         </ListItemIcon>
         <ListItemText inset primary="Profile" />
         </ListItem>

           <ListItem button
           className={classes.nested}
           component="a"
           href="/settings/integrations"
           >
             <ListItemIcon>
               <IntegrationsIcon />
             </ListItemIcon>
             <ListItemText inset primary="Integrations" />
           </ListItem>

           <ListItem button
           className={classes.nested}
           component="a"
           href="/settings/branding"
           >
             <ListItemIcon>
               <PortalIcon />
             </ListItemIcon>
             <ListItemText inset primary="Branding" />
           </ListItem>

           <ListItem button
           className={classes.nested}
           component="a"
           href="/settings/account"
           >
             <ListItemIcon>
               <AccountIcon />
             </ListItemIcon>
             <ListItemText inset primary="Account" />
           </ListItem>

         </List>
       </Collapse>
      { /*<FormControlLabel
       className={classes.nested}
           control={
             <Switch
               checked={this.state.app_livemode}
               onChange={this.handleLiveMode}
               value="app_livemode"
               color="primary"
             />
           }
           label={this.state.app_livemode ? "Viewing Test Data" : "View Test Data" }
         />*/}
        </List>
           <Divider />
           <List component="nav">
             <ListItem button component="a" href="/logout">
               <ListItemText primary="Logout" />
             </ListItem>
           </List>
      </Drawer>
    );

    let before = null;
    let after = null;

    if (anchor === 'left') {
      before = drawer;
    } else {
      after = drawer;
    }

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes[`appBarShift-${anchor}`]]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="title" color="inherit" noWrap className={classes.flex}>
                {this.props.header ? this.props.header : "InstaPayments for Braintree"}
              </Typography>
              <Typography variant="subheading" color="inherit" noWrap>
                Hi {localStorage.getItem('first_name')}
                </Typography>
                <IconButton
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                        className={classes.avatar}
                      >
                <Avatar aria-label="Avatar" className={classes.avatar} src={localStorage.getItem('gravatar')} />
                </IconButton>
            </Toolbar>
          </AppBar>
          {before}
          <main
            className={classNames(classes.content, classes[`content-${anchor}`], {
              [classes.contentShift]: open,
              [classes[`contentShift-${anchor}`]]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {children}
          </main>
          {after}
        </div>
      </div>
    );
  }
}

NavBar2.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavBar2);
