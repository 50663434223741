import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import { Redirect } from 'react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './views/login/LoginPage.js';
import Register2 from './views/register/RegisterPage.js';
import Dashboard from './views/dashboard/DashboardPage.js';
import Customers from './views/customers/CustomersPage.js';
import Checkouts from './views/checkouts/CheckoutsPage.js';
import Account from './views/settings/account.js';
import Profile from './views/settings/profile.js';
import Integrations from './views/settings/integrations.js';
import Portal from './views/settings/branding.js';
import Forms from './views/forms/FormsPage.js';
import NewForms from './views/forms/CreateFormsPage.js';
import UpdateForm from './views/forms/UpdateFormsPage.js';

import Products from './views/products/ProductsPage.js';
import NewProducts from './views/products/CreateProductsPage.js';
import UpdateProduct from './views/products/UpdateProductsPage.js';

//import UpdateForms from './views/forms/UpdateFormsPage.js'; //need to clean up file first
import NotFound from './views/NotFound.js';
import logo from './logo.svg';
import './App.css';


function loggedIn() {
  console.log("loggedIn() was called")
  let token = localStorage.getItem('token');
  let timestamp = localStorage.getItem('timestamp');
  let potentialExp = timestamp/1000 + 7200; // 2 hours after token was created in seconds
  /*Checks if there's a token in local storage and if the timestamp is older than 2 hours.
    These are simply quick front end checks. All auth is really done server side.
  */
  if( token != null && timestamp != null && potentialExp > new Date().getTime()/1000){
    console.log("the user met logged in condition")
    return true;
   }else{
    console.log(" the user did NOT meet logged in condition")
    return false
   }
}


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8551fd',
      main: '#814ff8',
      dark: '#051550',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2f7fe',
      main: '#B0BEC5',
      dark: '#B0BEC5',
      contrastText: '#fff',
    },
  }
});

class PrimaryLayout extends Component {
  render() {
    return (
      <div className="primary-layout">
        <main>
        <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Register2} />

        <Route path="/dashboard" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Dashboard {...props}/>
          )
        )}/>

        <Route path="/customers" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Customers {...props}/>
          )
        )}/>

        <Route path="/checkouts" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/checkouts`}/>
          ) : (
            <Checkouts {...props}/>
          )
        )}/>

        <Route path="/forms" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Forms {...props}/>
          )
        )}/>

        <Route path="/forms/new" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <NewForms {...props}/>
          )
        )}/>

        <Route path="/forms/update/:formId" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <UpdateForm {...props}/>
          )
        )}/>

        <Route path="/products" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Products {...props}/>
          )
        )}/>

        <Route path="/products/new" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <NewProducts {...props}/>
          )
        )}/>

        <Route path="/products/update/:productId" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <UpdateProduct {...props}/>
          )
        )}/>

        <Route path="/settings/account" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Account {...props}/>
          )
        )}/>

        <Route path="/settings/profile" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Profile {...props}/>
          )
        )}/>

        <Route path="/settings/branding" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/login`}/>
          ) : (
            <Portal {...props}/>
          )
        )}/>

        <Route path="/settings/integrations" exact render={(props) => (
          !loggedIn() ? (
            <Redirect to={`/forms`}/>
          ) : (
            <Integrations {...props}/>
          )
        )}/>



        <Route exact component={NotFound} />
      </Switch>
        </main>
        </div>
    );
  }
}

const App = () => (
  <BrowserRouter>
  <MuiThemeProvider theme={theme}>
  <PrimaryLayout />
  </MuiThemeProvider>
  </BrowserRouter>
)

export default App;
