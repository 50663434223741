import Paper from '@material-ui/core/Paper';
import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import queryString from 'qs';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Snackbar from '@material-ui/core/Snackbar'
//import stripeButton from '../../images/stripe-button.png'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import {StripeProvider} from 'react-stripe-elements';
import MyStoreCheckout from '../../components/checkout/MyStoreCheckout';
import MySnackbarContentWrapper from '../../components/messages/customSnack';

const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root:{
    marginTop:15,
    width: "50%",
    marginLeft: "25%"
  },
  background:{
    //backgroundColor: "grey"
  },
  paper: theme.mixins.gutters({
    paddingTop: 20,
    paddingBottom: 50,
    marginTop: theme.spacing.unit * 3,
  }),
  container: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 textField: {
   marginLeft: theme.spacing.unit,
   marginRight: theme.spacing.unit,
 },
 textFieldHalf:{
   marginLeft: theme.spacing.unit * .5,
   marginRight: theme.spacing.unit * .5,
   width: "48%"
 },
 formControl: {
   marginTop: theme.spacing.unit,
   minWidth: "100%",
 },
 button: {
   //width:00,
    margin: theme.spacing.unit,
  },
  bigAvatar: {
  width: 75,
  height: 75,
   backgroundColor: "#fff"
  },
 menu: {
   width: 100,
 },
 logo:{
 maxWidth: "60px",
 maxHeight: "60px",
}

});

function getSteps() {
  return ['Organization Info', 'Braintree Connection', 'Free Trial'];
}



class Register2 extends React.Component {
  constructor(props){
    super(props);
    this.state={
      type: 'production',
      username:'test',
      password:'',
      messageError:'',
      messageSuccess:'',
      activeStep: 0,
      createOrgDisabled: false
    }
  }

  componentDidMount(event){
    const query = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
    console.log(query)
    if (query.step) {
      this.setState({
        activeStep: Number(query.step) || 0
      })
    }
    if (query.code) {
      this.updateOrg(query.code)
    }
    if(localStorage.getItem('token')){
      this.fetchUser()
    }

  }

  fetchUser = () => {
    var self = this;
    axios({
     method: 'get',
     url: `${apiBaseUrl}/v1/org/me`, //retrieves the current authenticated user
     headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
   })
    .then(function (response) {
      if(response.status === 200){
        self.setState({
        org: response.data,
        stripe_account: response.data.stripe_credentials && response.data.stripe_credentials.stripe_user_id,
        finished: true
        });
        console.log(self.state.org.billing)
      }else{
        let path = `/${self.state.org_handle}/login`
        self.props.history.push(path);
      }
    }).catch(function (error) {
      console.log(error);
      self.setState({messageError:"Error retrieving user profile"})
    });
  }

  updateOrg = (code) => {
    var self = this;
    self.setState({
    stripeUpdateFinished: false
    });
    axios({
     method: 'POST',
     url: `${apiBaseUrl}/v1/org/settings/stripe/update`,
     headers: {
       'Authorization': 'Bearer ' + localStorage.getItem('token'),
       'Content-Type' : 'application/json'
     },
     data:{
       'code': code,
     }
   })
    .then(function (response) {
      if(response.status === 200){
        self.setState({
        stripeUpdateFinished: true
        });
        self.fetchUser()
      }})
    .catch(function (error) {
      console.log(error);
      self.fetchUser()
      self.setState({messageError:"Error updating user"})
    });
  }

  handleChange = name => event => {
   this.setState({
     [name]: event.target.value,
   });
 };

 handleSnackClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   this.setState({ snackOpen: false });
 };

 loginUser = () => {
   var self =this;
   var payload={
     "username":this.state.username,
     "password":this.state.password,
   }
   if(!payload.username || !payload.password){
     self.setState({messageError:"These fields are required"});
   }else{
   axios.post(`${apiBaseUrl}/v1/org/${this.state.org_handle.toLowerCase()}/login`, payload)
   .then(function (response) {
     console.log(response);
     if(response.status === 200){
         localStorage.setItem('token', response.data.token);
         localStorage.setItem('timestamp', new Date().getTime());
         localStorage.setItem('name', `${response.data.first_name} ${response.data.last_name}`);
         localStorage.setItem('first_name', response.data.first_name);
         localStorage.setItem('last_name', response.data.last_name);
         localStorage.setItem('gravatar', `https://www.gravatar.com/avatar/${response.data.gravatar}?d=mp`);
         localStorage.setItem('org', response.data.org);
         localStorage.setItem('org_id', response.data.org_id);
         localStorage.setItem('admin_id', response.data.admin_id);
         localStorage.setItem('intercom_hash', response.data.intercom_hash);
         self.setState({
           activeStep: 1
         })
         self.fetchUser()
     }})
   .catch(function (error) {
     console.log(error);
     self.setState(
       {
         usernameMessage:"Incorrect Username or Password",
         passwordMessage:"Incorrect Username or Password",
         usernameInvalid: true,
         passwordInvalid: true,
         snackOpen: true,
         snackVariant:"Error",
         snackMessage:"Your logging you in as a new user."
     })
   });
 }
 }

 updateOrg = (event) => {
   event.preventDefault()
   var self = this;
   self.setState({
   braintreeUpdateFinished: false
   });
   axios({
    method: 'POST',
    url: `${apiBaseUrl}/v1/org/settings/integrations/braintree`,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type' : 'application/json'
    },
    data:{
      "type": this.state.type,
      "private_key": this.state.private_key,
      "sandbox_private_key": this.state.sandbox_private_key,
      "public_key": this.state.public_key,
      "sandbox_public_key": this.state.sandbox_public_key,
      "merchant_id": this.state.merchant_id,
      "sandbox_merchant_id": this.state.sandbox_merchant_id,
      "tokenization_key": this.state.tokenization_key,
      "sandbox_tokenization_key": this.state.sandbox_tokenization_key
    }
  })
   .then(function (response) {
     if(response.status === 200){

       self.setState({
         braintreeUpdating: false,
         snackOpen: true,
         snackVariant:"success",
         snackMessage:"Credentials have been updated."
       });
       self.fetchUser()
     }})
   .catch(function (error) {
     console.log(error);
     self.setState({
       braintreeUpdating: false,
       snackOpen: true,
       snackVariant:"error",
       snackMessage:"Error updating credentials. Double check they were copied correctly."
     });
     self.fetchUser()
     self.setState({messageError:"Error updating user"})
   });
 }

createOrg(event){
    var self = this;
    event.preventDefault();
    this.setState({
      createOrgDisabled: true
    })
    var payload={
      "first_name":this.state.first_name,
      "last_name":this.state.last_name,
      "phone":this.state.phone,
      "organization_name":this.state.company_name,
      "username":this.state.username,
      "email":this.state.username,
      "password":this.state.password
    }
    console.log(payload)
    if(!payload.username || !payload.password){
      self.setState({messageError:"These fields are required"});
    }else{
    axios.post(`${apiBaseUrl}/v1/org/new`, payload)
    .then(function (response) {
      console.log(response);
      if(response.status === 200){
        self.setState({
          createOrgDisabled: false
        })
        self.loginUser()

      }else{
        let path = `/register`
        self.props.history.push(path);
      }})
    .catch(function (error) {
      console.log("response", error);
      if (error.response.status === 422) {
        self.setState({
          createOrgDisabled: false,
          snackOpen: true,
          snackVariant:"error",
          snackMessage:error.response.data.errorsArray[0].msg
        })
      }else{
        self.setState({
          createOrgDisabled: false,
          snackOpen: true,
          snackVariant:"error",
          snackMessage:"Could not create new Org."
        })
      }




    });
  }
}

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return (
    <div className={classes.background}>


    <Grid container className={classes.root} justify={"center"}>
      <Paper className={classes.paper} elevation={4}>
      <Grid container justify={"center"}>
      <Avatar aria-label="Logo" className={classes.bigAvatar}>
            <img className={classes.logo} src="/images/icon2.png" alt="logo"/>
      </Avatar>
      </Grid>
      <Grid container justify={"center"}>
      <div style={{width: "100%"}}>
      <Stepper activeStep={activeStep} alternativeLabel>
       {steps.map(label => {
         return (
           <Step key={label}>
             <StepLabel>{label}</StepLabel>
           </Step>
         );
       })}
     </Stepper>

     </div>
      </Grid>

      { activeStep === 0 ?
      <form className={classes.container} onSubmit={(event) => this.createOrg(event)}>

        <Grid container justify={"center"}>
        <TextField
        label="First Name"
        name="first_name"
        required
        className={classes.textFieldHalf}
        helperText="Enter your first name"
        onChange={this.handleChange('first_name')}
        />

        <TextField
        label="Last Name"
        name="last_name"
        required
        className={classes.textFieldHalf}
        helperText="Enter your last name"
        onChange={this.handleChange('last_name')}
        />
        </Grid>
        <Grid container justify={"center"}>
        <TextField
        label="Email"
        name="email"
        required
        fullWidth
        className={classes.textFieldHalf}
        helperText="Enter your username"
        onChange={this.handleChange('username')}
        />
        <TextField
        label="Phone"
        required
        name="phone"
        fullWidth
        className={classes.textFieldHalf}
        helperText="Enter your phone number"
        onChange={this.handleChange('phone')}
        />
        </Grid>

        <TextField
        label="Organization Name"
        name="company"
        required
        fullWidth
        className={classes.textField}

        onChange={this.handleChange('company_name')}
        />
        <br />

        <TextField
        label="Password"
        required
        fullWidth
        name="password"
        type="password"
        className={classes.textField}
        helperText="Enter your password"
        onChange={this.handleChange('password')}
        />
        <Grid container justify={"center"}>
        <Button
        variant="raised"
        color="primary"
        fullWidth="true"
        disabled={this.state.createOrgDisabled}
        type="submit"
        className={classes.button}

        >
        Get Started
        </Button>
        </Grid>

        </form>
      :null}

      {activeStep === 1 ?

        <form autocomplete="off" onSubmit={this.updateOrg}>
        <Grid container justify={"center"}>
        <div>
        <FormControl className={styles.formControl}>
        <Select
        native
        required
        value={this.state.kind}
        onChange={this.handleChange('type')}
        inputProps={{
          name: 'Type',
          id: 'type',
          required: true
        }}
        >
        <option selected value="production">Production</option>
        <option value="sandbox">Sandbox</option>
        </Select>
        <FormHelperText>Credentials Type</FormHelperText>
        </FormControl>
        </div>
        { this.state.type == "production" ?
        <div>
        <TextField
        label="Secret Key"
        name="braintree_secrent"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Braintree Secret"
        onChange={this.handleChange('braintree_secret')}
        />
        <TextField
        label="Public Key"
        name="public_key"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Braintree Public Key"
        onChange={this.handleChange('braintree_public')}
        />
        <TextField
        label="Merchant ID"
        name="merchant_id"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Braintree Merchant ID"
        onChange={this.handleChange('braintree_merchant_id')}
        />
        <TextField
        label="Tokenization Key"
        name="tokenization_key"
        required
        className={classes.textFieldHalf}
        helperText="Enter a Braintree Tokenization Key"
        onChange={this.handleChange('tokenization_key')}
        />
        </div>
        :
        <div>
        <TextField
        label="Sandbox Secret Key"
        name="braintree_secrent"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Sandbox Braintree Secret"
        onChange={this.handleChange('sandbox_braintree_secret')}
        />
        <TextField
        label="Sandbox Public Key"
        name="public_key"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Sandbox Braintree Public Key"
        onChange={this.handleChange('sandbox_braintree_public')}
        />
        <TextField
        label="Sandbox Merchant ID"
        name="merchant_id"
        required
        className={classes.textFieldHalf}
        helperText="Enter your Sandbox Braintree Merchant ID"
        onChange={this.handleChange('sandbox_braintree_merchant_id')}
        />
        <TextField
        label="Sandbox Tokenization Key"
        name="tokenization_key"
        required
        className={classes.textFieldHalf}
        helperText="Enter a Sandbox Braintree Tokenization Key"
        onChange={this.handleChange('sandbox_tokenization_key')}
        />
        </div>}
        <br />
        <Button
        variant="raised"
        color="primary"
        fullWidth="true"
        disabled={this.state.createOrgDisabled}
        type="submit"
        className={classes.button}

        >
        Connect with Braintree
        </Button>
        </Grid>
        </form>
      :null}

      {activeStep === 2 ?
        <StripeProvider apiKey="pk_live_MFpg2VvUxONHCsbIqLKrrnEh">
          <MyStoreCheckout {...this.props}/>
        </StripeProvider>
        : null
      }
      </Paper>


    </Grid>
    <Snackbar
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
       }}
       open={this.state.snackOpen}
       autoHideDuration={3000}
       onClose={this.handleSnackClose}
       >
         <MySnackbarContentWrapper
         variant={this.state.snackVariant}
         message={this.state.snackMessage}
         onClose={this.handleSnackClose}
        />
    </Snackbar>
    </div>
  );
}
}



/*PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};*/

export default withStyles(styles)(Register2);
